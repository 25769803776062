import React from 'react'
import { useAuth } from '../context/AuthProvider';

interface Props {
  inverted?: boolean
}

export default function Header({ inverted }: Props) {
  const { user, signOut } = useAuth();
  return (
    <div className="absolute w-screen z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 w-full">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/" className={`whitespace-nowrap text-5xl font-medium ${inverted ? "text-white" : "text-sky-600"}`}>
              pwuf.me
            </a>
          </div>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a href="/analytics" className={`mr-8 whitespace-nowrap text-base font-medium ${inverted ? "text-white hover:text-gray-200" : "text-sky-600 hover:text-sky-700"}`}>
              Analytics
            </a>
            {
              user ? (
                <>
                  <a
                    href="/"
                    className={`whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${inverted ? "bg-white hover:bg-gray-200 text-sky-600" : "bg-sky-600 hover:bg-sky-700 text-white"}`}
                    onClick={signOut}
                  >
                    Log out
                    </a>
                </>
              ) : (
                  <>
                    <a href="/login" className={`mr-8 whitespace-nowrap text-base font-medium ${inverted ? "text-white hover:text-gray-200" : "text-sky-600 hover:text-sky-700"}`}>
                      Sign in
                    </a>
                    <a
                      href="/register"
                      className={`whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${inverted ? "bg-white hover:bg-gray-200 text-sky-600" : "bg-sky-600 hover:bg-sky-700 text-white"}`}
                    >
                      Sign up
                    </a>
                  </>
                )
            }
          </div>
        </div>
      </div>
    </div>
  )
}