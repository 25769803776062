import React, { ReactElement, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router'
import Header from '../components/Header';
import PageContainer from '../components/PageContainer';
import { ReactComponent as RocketSVG } from '../assets/Rocket.svg'
import { ReactComponent as LoadingSVG } from '../assets/Loading.svg'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

interface Props {

}

const API_URL = process.env.REACT_APP_API_URL;

export default function Redirect({ }: Props): ReactElement {
    const { alias } = useParams();

    const [reqPass, setReqPass] = useState(false);
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        //window.location.href = `${process.env.REACT_APP_API_URL}/${alias}`;
        handleRedirect();
    }, []);

    const navigate = useNavigate();
    const handleRedirect = () => {
        axios.get(`${API_URL}/${alias}${password ? `?pwd=${password}` : ""}`).then((res) => {
            window.location.href = `${API_URL}/${alias}${password ? `?pwd=${password}` : ""}`
        }).catch(err => {
            const res = err.response;
            if (res) {
                if (res.status == 403) {
                    if (reqPass) {
                        toast("Incorrect Password", { type: "warning", position: "top-center"})
                    }
                    setReqPass(true);
                }
                if (res.status == 400) {
                    setError("This link doesn't appear to exist");
                }
            } else {
                window.location.href = `${process.env.REACT_APP_API_URL}/${alias}?pwd=${password}`
            }
        })
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleRedirect();
    }

    return <div className="h-[100vh] bg-sky-600">
        <PageContainer className="items-center justify-center h-full">
            <ToastContainer />
            <RocketSVG className="absolute w-full z-0" />
            {
                reqPass ? (
                    <form
                        className="flex flex-col bg-white p-3 rounded-md shadow-xl w-full max-w-[600px] items-stretch z-10"
                        onSubmit={handleSubmit}
                    >
                        <label className="text-sky-600 text-xl text-center">This link is password protected</label>
                        <input
                            className="p-3 bg-gray-200 rounded-md text-center text-xl outline-none"
                            value={password}
                            placeholder="enter the password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <input
                            className="p-2 text-xl text-center mt-3 bg-sky-600 rounded hover:bg-sky-700 text-white cursor-pointer"
                            type="submit"
                            value="Go!"
                        />
                    </form>
                ) : (
                    error ? (
                        <div className="flex flex-col p-3 rounded-md shadow-xl w-full max-w-[600px] items-stretch z-10 bg-white">
                            <h2 className="text-3xl text-sky-600 text-center">Oops...</h2>
                            <h3 className="text-2xl text-gray-500 text-center">{error}</h3>
                        </div>
                    ) : (
                        <LoadingSVG className="absolute h-50 w-full z-0 opacity-50" />
                    )
                )
            }
        </PageContainer>
    </div>;
}
