import React, { ReactElement, useRef, useState } from 'react'
import Header from '../components/Header'
import { ReactComponent as HomeSVG } from '../assets/home.svg'
import { ReactComponent as LoadingSVG } from '../assets/Loading.svg'
import FormBody from '../components/FormBody'
import { useAuth } from '../context/AuthProvider'
import { LockClosedIcon, CheckCircleIcon, XCircleIcon, ClipboardCopyIcon, ClipboardCheckIcon } from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';
import PageContainer from '../components/PageContainer'
import axios from 'axios'
import { throttle } from 'lodash';
import { ToastContainer, toast } from 'react-toastify'
import { Transition } from '@headlessui/react'

const API_URL = process.env.REACT_APP_API_URL;
const END_URL =  process.env.REACT_APP_END_URL;

export default function Home(): ReactElement {
    const { user } = useAuth();

    const [redirect, setRedirect] = useState("");
    const [alias, setAlias] = useState("");
    const [password, setPassword] = useState("");
    const [taken, setTaken] = useState(true);
    const [isCopied, setIsCopied] = useState(false);

    const [result, setResult] = useState(null);

    const [loading, setLoading] = useState(false);

    const handleChangeAlias = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const cleanedVal = value.replace(/[^A-Za-z0-9_.-]/, "");

        setAlias(cleanedVal);
        if (cleanedVal) {
            debouncedGetTaken(cleanedVal);
        } else {
            setTaken(true);
        }

    }

    const getTaken = (value: string) => {
        axios.get(`${API_URL}/link/` + value).then((res) => {
            const data = res.data;
            setTaken(data.taken);
        });
    }

    const debouncedGetTaken = throttle(getTaken, 300);

    const handleSubmit = () => {
        const payLoad = {
            redirect,
            alias,
            password
        }

        const config = user ? {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        } : {};

        setLoading(true);
        axios.post(`${API_URL}/link`, payLoad, config).then(res => {
            const data = res.data;
            setAlias(data.alias);
            setResult(res.data);
            setLoading(false);
        }).catch(err => {
            const message = err.response.data.message;
            toast(message, { position: "top-center", type: "error" });
            setLoading(false);
        })
    }

    async function copyTextToClipboard(text: string) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const handleCopyClick = () => {
        copyTextToClipboard(`${END_URL}/${alias}`)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div className="">
            <Header />
            <ToastContainer />
            <PageContainer className="relative items-center md:items-start h-screen justify-center">
                <HomeSVG className="absolute hidden sm:block bottom-0 right-0 md:-translate-y-1/3 z-0" />
                <h1 className="relative md:absolute left-0 top-0 text-sky-600 font-medium text-2xl md:mt-32 mb-6 md:ml-24 select-none">A simple, fast url shortener <br />with powerful analytics.</h1>
                {
                    result == null ? (
                        <Transition
                            show={!result}
                            leave="transform transition duration-[1400ms]"
                            leaveFrom=""
                            leaveTo="translate-x-48"
                            className="z-10"
                        >
                            <FormBody title="Create a url!">
                                <label className="text-sky-600 font-light text-xl mt-4">Your Link</label>
                                <input
                                    className="text-xl text-gray-500 border-b border-gray-200 outline-none"
                                    placeholder="enter that looooooong url!"
                                    onChange={(e) => setRedirect(e.target.value)}
                                    value={redirect}
                                />
                                <div className="flex flex-row flex-nowrap mt-4 items-center">
                                    <label className="text-sky-600 font-light text-xl mr-1">Custom Alias</label>
                                    {
                                        !user && (<>
                                            <LockClosedIcon data-tip="Make an account for custom aliases" className="h-5 w-5 text-gray-500" />
                                            <ReactTooltip />
                                        </>)
                                    }
                                </div>
                                <div className="relative flex">
                                    <h3 className="text-xl text-gray-500 border-b border-gray-200">https://pwuf.me/</h3>
                                    <input
                                        className="text-xl text-gray-500 border-b border-gray-200 outline-none disabled:bg-white"
                                        placeholder="alias"
                                        disabled={!user}
                                        onChange={handleChangeAlias}
                                        value={alias}
                                    />
                                    {
                                        !taken ? (
                                            <CheckCircleIcon className="absolute text-green-300 right-0 h-6 mr-2" />
                                        ) : (
                                                <XCircleIcon className="absolute text-gray-300 right-0 h-6 mr-2" />
                                            )
                                    }
                                </div>
                                <div className="flex flex-row mt-4 items-center">
                                    <label className="text-sky-600 font-light text-xl mr-1">Password Protect It!</label>
                                    {
                                        !user && (<>
                                            <LockClosedIcon data-tip="Make an account for protected urls" className=" h-5 w-5 text-gray-500" />
                                            <ReactTooltip />
                                        </>)
                                    }
                                </div>
                                <input
                                    className="text-xl text-gray-500 border-b border-gray-200 outline-none"
                                    placeholder="password (optional)"
                                    disabled={!user}
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                />

                                <button
                                    className={`flex justify-center bg-sky-600 mt-4 rounded-md text-white text-xl p-2 hover:bg-sky-700 ${loading ? "bg-sky-700" : ""}`}
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    {
                                        loading ? (
                                            <LoadingSVG className="h-6" />
                                        ) : "Create Link!"
                                    }
                                </button>
                            </FormBody>
                        </Transition>
                    ) : (
                            <Transition
                                show={result != null}
                                enter="transform transition duration-[400ms]"
                                enterFrom="opacity-50 scale-[50]"
                                className="z-10"
                            >
                                <FormBody title="Here's your link!">
                                    <label className="text-sky-600 font-light text-xl mt-4">Your Link</label>
                                    <div className="relative">
                                        <h2 className="select-all text-gray-700 py-2 px-1 border border-gray-600 bg-gray-200 rounded-md">
                                            {END_URL}/{alias}
                                        </h2>
                                        {
                                            !isCopied ? (
                                                <ClipboardCopyIcon
                                                    className="absolute h-6 right-1 top-0 translate-y-[40%] text-gray-500 hover:text-gray-600"
                                                    onClick={handleCopyClick}
                                                />
                                            ) : (
                                                    <ClipboardCheckIcon
                                                        className="absolute h-6 right-1 top-0 translate-y-[40%] text-green-400"
                                                    />
                                                )
                                        }
                                    </div>

                                </FormBody>
                            </Transition>
                        )
                }

            </PageContainer>
        </div>
    )
}