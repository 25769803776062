import React, { ReactElement } from 'react'
import Header from '../components/Header'
import { ReactComponent as NotFoundSVG } from '../assets/404.svg'
import PageContainer from '../components/PageContainer'

export default function Error(): ReactElement {
    return (
        <div className="h-[100vh]">
            <Header />
            <PageContainer className="items-center justify-center h-full">
                <NotFoundSVG className="w-full"/>
            </PageContainer>
        </div>
    )
}

