import React, { ReactElement, useState, useEffect } from 'react'
import Header from '../components/Header'
import PageContainer from '../components/PageContainer'
import { Transition } from '@headlessui/react'
import { useParams, useNavigate } from 'react-router'
import topographySvg from '../assets/topography.svg'
import { TrashIcon, EyeIcon } from '@heroicons/react/solid'
import ReactTooltip from 'react-tooltip'
import { AnalyticsBar } from '../components/AnalyticsBar'
import axios from 'axios'
import { useAuth } from '../context/AuthProvider'
import Moment from 'react-moment'

const API_URL = process.env.REACT_APP_API_URL;


export default function Alias(): ReactElement {
    const { alias } = useParams();
    const { user } = useAuth();

    const [clicksToday, setClicksToday] = useState<number>(0);
    const [totalClicks, setTotalClicks] = useState<number>(0);
    const [uniqueClicks, setUniqueClicks] = useState<number>(0);

    const [password, setPassword] = useState(null);
    const [redirect, setRedirect] = useState("");
    const [createdAt, setCreatedAt] = useState(new Date());

    const [data, setData] = useState<Array<number>>([]);


    useEffect(() => {
        axios.get(`${API_URL}/analytics/${alias}`, {
            headers: {
                Authorization: `Bearer ${user?.token}`
            }
        }).then((res) => {
            const data = res.data;
            setData(data.data);
            setClicksToday(data.data[new Date().getDay()])
            setCreatedAt(data.createdAt);
            setPassword(data.password);
            setRedirect(data.redirect);
            setTotalClicks(data.total);
            setUniqueClicks(data.unique);
        })
    }, []);

    const navigate = useNavigate();
    const handleDelete = () => {
        axios.delete(`${API_URL}/link?alias=${alias}`, {
            headers: {
                Authorization: `Bearer ${user?.token}`
            }
        }).then(() => {
            navigate('/analytics');
        });
    }

    return (
        <div>
            <Header inverted />
            <div
                className="absolute bg-sky-600 w-full h-[20rem]"
            //style={{ backgroundImage: `url(${topographySvg})` }}
            />
            <PageContainer>
                <div className="relative h-[20rem] flex">
                    <div className="mt-auto w-full">
                        <Transition
                            appear
                            show
                            enter="transform transition duration-[400ms]"
                            enterFrom="-translate-x-48 -translate-y-48 opacity-0 scale-50"
                            enterTo=""
                        >
                            <h1 className="text-white text-[7rem] select-none">{alias}</h1>
                        </Transition>
                        <Transition
                            appear
                            show
                            enter="transform transition duration-[400ms]"
                            enterFrom="-translate-x-96 opacity-0 scale-50"
                            enterTo=""
                        >
                            <h4 className="text-white text-2xl select-none max-w-[80%] truncate">{redirect}</h4>
                        </Transition>
                    </div>
                    <TrashIcon
                        className="absolute my-2 text-white h-10 right-0 bottom-0 hover:text-red-500"
                        data-tip="Delete alias"
                        onClick={handleDelete}
                    />
                    <ReactTooltip />
                </div>
                <div className="flex flex-row justify-space mt-2">
                    <a href="/analytics" className="text-xl text-gray-500">dashboard/</a>
                    <a className="text-xl text-gray-500">{alias}</a>
                    <h2 className="text-xl text-gray-500 ml-auto">Created: <Moment fromNow ago>{createdAt}</Moment> ago</h2>
                </div>
                <Transition show appear>
                    <div className="flex flex-col sm:flex-row justify-evenly mt-2">
                        <Transition.Child
                            enter="transform transition delay-[400ms] duration-[400ms]"
                            enterFrom="opacity-0 scale-50"
                            enterTo=""
                        >
                            <div className="flex flex-col align-center mt-4">
                                <h2 className="text-sky-600 text-2xl text-center">Clicks Today</h2>
                                <h3 className="text-gray-500 text-6xl text-center">{clicksToday}</h3>
                            </div>
                        </Transition.Child>
                        <Transition.Child
                            enter="transform transition delay-[700ms] duration-[400ms]"
                            enterFrom="opacity-0 scale-50"
                            enterTo=""
                        >
                            <div className="flex flex-col align-center mt-4">
                                <h2 className="text-sky-600 text-2xl text-center">Total Clicks</h2>
                                <h3 className="text-gray-500 text-6xl text-center">{totalClicks}</h3>
                            </div>
                        </Transition.Child>
                        <Transition.Child
                            enter="transform transition delay-[1000ms] duration-[400ms]"
                            enterFrom="opacity-0 scale-50"
                            enterTo=""
                        >
                            <div className="flex flex-col align-center mt-4">
                                <h2 className="text-sky-600 text-2xl text-center">Unique Clicks</h2>
                                <h3 className="text-gray-500 text-6xl text-center">{uniqueClicks}</h3>
                            </div>
                        </Transition.Child>
                    </div>
                </Transition>
                <Transition
                    show
                    appear
                    enter="transform transition delay-[1300ms] duration-[400ms]"
                    enterFrom="opacity-0 translate-y-96"
                    enterTo="opacity-100"
                >
                    <div className="hidden sm:flex justify-center mt-8">
                        <div className="w-full h-3/5 p-6 border border-gray-200 shadow-xl rounded-md">
                            <h2 className="text-3xl text-center mb-2 text-gray-500">This Week's Visits</h2>
                            <AnalyticsBar data={data} />
                        </div>
                    </div>
                </Transition>
                {
                    password ? (
                        <div className="mt-8">
                            <h2 className="text-sky-600 text-4xl text-center mb-1">Link Password</h2>
                            <div className="flex flex-row justify-center items-center border border-gray-200 rounded p-3 mb-4">
                                <h2 className="text-gray-500 text-4xl text-center">{password}</h2>
                                <EyeIcon
                                    className="h-8 ml-auto mr-2 text-gray-500 hover:text-gray-700"
                                    data-tip="View password"
                                />
                                <ReactTooltip />
                            </div>
                        </div>
                    ) : (<></>)
                }
            </PageContainer>
        </div>
    )
}
