import React from 'react';

import AuthProvider from './context/AuthProvider';
import Navigation from './navigation/Navigation';

function App() {
  return (
    <AuthProvider>
      <Navigation />
    </AuthProvider>
  );
}

export default App;
