import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  LineElement,
  PointElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { stringify } from 'querystring';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false,
    },
  },
};

const labels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

interface Props {
  data: Array<Object>
}

export function AnalyticsBar({ data }: Props) {
  const config: Data = {
    labels,
    datasets: [
      {
        label: 'All time',
        data: data,
        cubicInterpolationMode: "monotone",
        borderColor: '#0284C799',
        backgroundColor: '#0284C7',
      }
    ],
  };

  return <Line options={options} data={config} height={100} />;
}

interface Data {
  labels: string[],
  datasets: Array<DataSet>
  options?: Object
}

interface DataSet {
  label: string,
  data: Array<any>
  cubicInterpolationMode: "monotone" | "default",
  borderColor: string,
  backgroundColor: string
}
