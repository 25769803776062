import React, { ReactElement } from 'react'
import { Transition } from '@headlessui/react'

interface Props {
    title: string,
    children: Array<ReactElement>
}

export default function FormBody({ title, children }: Props): ReactElement {
    return (
        <Transition
            appear
            show
            enter="transform transition duration-[400ms]"
            enterFrom="scale-90"
            enterTo="scale-100"
            className="z-10"
        >
            <div className="bg-white p-4 shadow-2xl rounded-md border-gray-200 border z-10 mx-0 w-[400px] md:mx-24">
                <h2 className="text-sky-600 font-medium text-3xl">{title}</h2>
                <form  onSubmit={(e) => e.preventDefault()} className="flex flex-col">
                    {children}
                </form>
            </div>
        </Transition>
    )
}
