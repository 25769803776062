import React, { ReactElement } from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from './../pages/Home';
import Error from './../pages/Error';
import Login from './../pages/Login';
import Register from './../pages/Register';
import AnalyticsNoUser from './../pages/AnalyticsNoUser';
import { useAuth } from '../context/AuthProvider';
import Analytics from '../pages/Analytics';
import Alias from '../pages/Alias';
import Redirect from '../pages/Redirect';
import Password from '../pages/Redirect';

export default function Navigation(): ReactElement {
    const { user } = useAuth();
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={user ? <Home /> : <Login />} />
                <Route path="/register" element={user ? <Home /> : <Register />} />
                <Route path="/analytics" element={user ? <Analytics /> : <AnalyticsNoUser />} />
                <Route path="/analytics/:alias" element={user ? <Alias /> : <AnalyticsNoUser />} />
                <Route path='/:alias' element={<Redirect />} />
                <Route path="/error" element={<Error />} />
                <Route path="*" element={<Error />} />
            </Routes>
        </Router>
    )
}
