import React, { ReactElement, useState } from 'react'
import Header from '../components/Header'
import { ReactComponent as LoginSVG } from '../assets/Login.svg'
import FormBody from '../components/FormBody'
import PageContainer from '../components/PageContainer'
import { useAuth } from '../context/AuthProvider'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login({ }): ReactElement {
    const { logIn } = useAuth();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [loading, setLoading] = useState(false);

    const handleLogin = () => {
        setLoading(true);
        if (logIn) {
            logIn(email, password).then(() => {
                setLoading(false);
            }).catch(err => {
                console.log(err)
                toast(err, {
                    position: "top-center",
                    type: "warning"
                });
                setLoading(false);
            })
        }
    }

    return (
        <div className="h-screen">
            <ToastContainer />
            <Header />
            <PageContainer className="relative items-center h-screen md:items-start justify-center">
                <LoginSVG className="absolute bottom-0 right-0 z-0 h-4/5 my-20" />
                <FormBody title="Welcome back!">
                    <label className="text-sky-600 font-light text-xl mt-4">Email</label>
                    <input
                        className="text-xl text-gray-500 border-b border-gray-200 outline-none"
                        placeholder="your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <label className="text-sky-600 font-light text-xl mt-4">Password</label>
                    <input
                        className="text-xl text-gray-500 border-b border-gray-200 outline-none"
                        placeholder="enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                    />

                    <button
                        className="bg-sky-600 mt-4 rounded-md text-white text-xl p-2 hover:bg-sky-700"
                        onClick={handleLogin}
                    >
                        Login
                    </button>
                </FormBody>
            </PageContainer>
        </div>
    )
}