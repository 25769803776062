import React, { ReactElement, useState, useEffect } from 'react'
import Header from '../components/Header'
import PageContainer from '../components/PageContainer'
import { Transition } from '@headlessui/react'
import topographySvg from '../assets/topography.svg'
import axios from 'axios'
import { useAuth } from '../context/AuthProvider'
import { link } from 'fs'
import { useNavigate } from 'react-router'

const API_URL = process.env.REACT_APP_API_URL;

export default function Analytics(): ReactElement {
    const { user } = useAuth();
    const [totalLinks, setTotalLinks] = useState<number>(0);
    const [totalClicks, setTotalClicks] = useState<number>(0);

    const [links, setLinks] = useState([]);

    let navigate = useNavigate();
    const handleRowClick = (alias: string) => {
        let path = `/analytics/${alias}`;
        navigate(path);
    }

    useEffect(() => {
        axios.get(`${API_URL}/analytics`, {
            headers: {
                Authorization: `Bearer ${user?.token}`
            }
        }).then((res) => {
            const data = res.data.data;
            setLinks(data);
            setTotalLinks(data.length);
            setTotalClicks(data.reduce((sum: number, obj: { clicks: string }) => sum += obj.clicks ? parseInt(obj.clicks) : 0, 0));
        })
    }, []);

    return (
        <div>
            <Header inverted />
            <div
                className="absolute bg-sky-600 w-full h-[20rem]"
            //style={{ backgroundImage: `url(${topographySvg})` }}
            />
            <PageContainer>
                <div className="h-[20rem] flex">
                    <div className="mt-auto">
                        <h1 className="text-white text-[7rem] select-none">Analytics</h1>
                    </div>
                </div>
                <Transition show appear>
                    <div className="flex flex-row justify-evenly mt-8">
                        <Transition.Child
                            enter="transform transition delay-[400ms] duration-[400ms]"
                            enterFrom="opacity-0 scale-50"
                            enterTo=""
                        >
                            <div className="flex flex-col align-center">
                                <h2 className="text-sky-600 text-2xl text-center">Your Links</h2>
                                <h3 className="text-gray-500 text-6xl text-center">{totalLinks}</h3>
                            </div>
                        </Transition.Child>
                        <Transition.Child
                            enter="transform transition delay-[700ms] duration-[400ms]"
                            enterFrom="opacity-0 scale-50"
                            enterTo=""
                        >
                            <div className="flex flex-col align-center">
                                <h2 className="text-sky-600 text-2xl text-center">Total Clicks</h2>
                                <h3 className="text-gray-500 text-6xl text-center">{totalClicks || 0}</h3>
                            </div>
                        </Transition.Child>
                    </div>
                    <Transition.Child
                        enter="transform transition delay-[700ms] duration-[400ms]"
                        enterFrom="opacity-0 translate-y-96"
                        enterTo=""
                    >
                        <div className="relative my-8 shadow-2xl rounded-md border border-gray-200 flex flex-col items-stretch">
                            <table className={`table-auto w-full ${!links.length && 'blur-[1px]'}`}>
                                <thead>
                                    <tr>
                                        <th className="font-medium text-gray-500 border-b bg-gray-100">Alias</th>
                                        <th className="font-medium text-gray-500 border-b bg-gray-100">Redirect</th>
                                        <th className="font-medium text-gray-500 border-b bg-gray-100">Total Clicks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        links.length ? (
                                            links.map((o: { alias: string, redirect: string, clicks: number, users: number }, i: number) => (
                                                <tr
                                                    key={`link-${i}`}
                                                    className={`hover:opacity-50 cursor-pointer text-center ${i % 2 === 0 ? "bg-sky-100" : "bg-white"}`}
                                                    onClick={() => handleRowClick(o.alias)}
                                                >
                                                    <td className={`py-4`}>{o.alias}</td>
                                                    <td className={`py-4 truncate max-w-[400px] ${i % 2 === 0 ? "bg-sky-100" : "bg-white"}`}>{o.redirect}</td>
                                                    <td className={`py-4`}>{o.clicks || 0}</td>
                                                </tr>
                                            ))
                                        ) : (
                                                Array(3).fill(0).map((_, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <td className={`py-6 ${idx % 2 === 0 ? "bg-sky-100" : "bg-white"}`} />
                                                            <td className={`py-6 ${idx % 2 === 0 ? "bg-sky-100" : "bg-white"}`} />
                                                            <td className={`py-6 ${idx % 2 === 0 ? "bg-sky-100" : "bg-white"}`} />
                                                        </tr>
                                                    )
                                                })
                                            )
                                    }
                                </tbody>
                            </table>
                            {
                                links.length ? (<></>) : (
                                    <div className="absolute h-full w-full flex flex-col justify-center align-center bg-[#00000011] rounded-md">
                                        <a href="/" className="p-3 px-8 mx-auto text-white bg-sky-600 font-medium text-xl rounded-md hover:bg-sky-700 text-center shadow-md">No links, create one!</a>
                                    </div>
                                )
                            }
                        </div>
                    </Transition.Child>
                </Transition>
            </PageContainer>
        </div>
    )
}
