import React, { ReactElement } from 'react'

interface Props {
    children: Array<ReactElement> | ReactElement
    className?: string,
}

export default function PageContainer({ children, className }: Props): ReactElement {
    return (
        <div className={`relative flex max-w-7xl flex-col mx-auto px-4 sm:px-6${className ? " " + className : ""} `}>
            { children }
        </div>
    )
}
