import React, { ReactElement } from 'react'
import Header from '../components/Header'
import { ReactComponent as AnalyticsSVG } from '../assets/analytics.svg'
import PageContainer from '../components/PageContainer'

export default function AnalyticsNoUser(): ReactElement {
    return (
        <div>
            <Header />
            <PageContainer className="items-center pt-32 h-screen">
                <div className="flex flex-col">
                    <h2 className="font-medium text-3xl text-sky-600 text-center mt-8">Create an Account to get<br />access to Advanced Analytics</h2>
                    <a href="register" className="mt-8 font-base text-4xl text-white p-4 bg-sky-600 shadow-2xl hover:shadow-xl hover:bg-sky-700 rounded-md text-center">
                        Create Account
                    </a>
                </div>
                <AnalyticsSVG className="h-100 p-16" />
            </PageContainer>
        </div>
    )
}
